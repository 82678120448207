<template>
  <v-container class="pa-0 ma-0 login-container" fluid>
    <v-row no-gutters class="login-row">
      <!-- Right side - Login form -->

      <v-col
        cols="12"
        md="8"
        order="1"
        order-md="2"
        class="d-flex flex-column align-stretch right-background py-6"
      >
        <div class="flex-grow-1 d-flex align-center justify-center" width="100%">
          <v-card flat max-width="400" width="100%" class="pa-8 transparent">
            <v-card-title class="text-h4 font-weight-bold mb-6">
              <transition appear name="slide-fade">
                <span>Login</span>
              </transition>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" @submit="login">
                <v-text-field
                  v-model="credential.store"
                  label="Tienda"
                  :rules="f_required"
                  id="store"
                  outlined
                  required
                  type="number"
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="credential.user_email"
                  :rules="f_required"
                  label="Usuario"
                  outlined
                  required
                  dense
                  type="email"
                ></v-text-field>
                <v-text-field
                  v-model="credential.user_password"
                  label="Clave"
                  type="password"
                  :rules="f_required"
                  outlined
                  required
                  dense
                ></v-text-field>

                <v-btn
                  :disabled="!valid"
                  color="success"
                  type="submit"
                  class="mr-4"
                  @click="validate"
                >
                  Ingresar
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>

      <!-- Left side - Patterned background with grouped and centered content -->
      <v-col cols="12" md="4" order="2" order-md="1" class="left-background">
        <v-sheet
          height="100%"
          width="100%"
          class="d-flex flex-column align-stretch right-background py-6"
          :style="{ background: `${$vuetify.theme.themes.light.primary}` }"
        >
          <div class="flex-grow-1 d-flex align-center justify-center" width="100%">
            <div class="text-center">
              <v-card
                flat
                max-width="400"
                width="100%"
                class="pa-8 transparent justify-center"
              >
                <transition appear name="fade-in"> </transition>
                <transition appear name="slide-fade">
                  <h1 class="text-h3 font-weight-bold mb-4 white--text">AI-Pos</h1>
                </transition>
                <transition appear name="fade-in">
                  <p class="text-h6 white--text">Punto de venta Inteligente.</p>
                </transition>
              </v-card>
            </div>
          </div>

          <!-- Footer -->
          <v-footer dark class="mt-auto pa-4 transparent">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn x-small text href="/terminos" class="mx-2"
                  >Términos y Condiciones</v-btn
                >
                <v-btn x-small text href="/privacidad" class="mx-2"
                  >Aviso de Privacidad</v-btn
                >
              </v-col>
              <v-col cols="12" class="text-center mt-2 caption">
                © {{ new Date().getFullYear() }} NGM Corp. Todos los derechos reservados.
              </v-col>
            </v-row>
          </v-footer>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createProfile from "../utils/createProfile.js";
export default {
  name: "Login",

  props: {
    nextUrl: {
      default: null,
    },
  },
  data() {
    return {
      errors: [],
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      credential: {
        store: null,
        user_email: null,
        user_password: null,
      },
      profile: createProfile(),
    };
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    document.getElementById("store").focus();
  },
  methods: {
    getLogo: function () {
      var images = require.context("../assets/", false, /\.jpg$/);
      return images("./logo.jpg");
    },
    validate() {
      this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      this.loading_status = true;
      var md5 = require("md5");
      this.credential.user_password = md5(this.credential.user_password);

      console.log(this.credential);

      webserver("login", this.credential, (data) => {
        console.log(data);
        this.loading_status = false;
        this.profile.name = data.profile.user_name;
        this.profile.email = data.profile.user_email;
        this.profile.token = data.profile.token;
        this.profile.picture = data.profile.user_photo;
        this.profile.socialName = "SW67";
        this.profile.user_access = data.profile.user_access;

        this.$store.dispatch("setProfile", this.profile);
        this.$store.dispatch("setSeller", data.store);
        window.token = data.token;

        window.profile = data.profile;
        window.curvas = data.curvas;
        window.store = data.store;
        window.seller = data.seller;

        window.settings = data.settings.reduce(function (r, a) {
          r[a.tabla] = r[a.tabla] || [];
          r[a.tabla].push(a);
          return r;
        }, Object.create(null));
        console.log(window.settings);

        this.$store.dispatch("setNavStatus", true);
        this.$store.dispatch("set_menu", true);

        if (this.nextUrl) {
          this.$router.push(this.nextUrl);
        } else {
          this.$router.push("/Home");
        }
      });
    },
  },
};
//background: url("bg-welcome.jpg") no-repeat center center fixed;
</script>

<style scoped>
.material-icons {
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; /* Mejora la renderización en iOS */
}

.login-container {
  height: calc(100vh);
  position: relative;
  overflow-y: auto;
}

.login-row {
  min-height: 100%;
}

.left-background {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 60px 60px;
}

.right-background {
  background-color: #f5f5f5;
}

.login-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}
.fade-in-enter {
  opacity: 0;
}

.fade-in-up-enter-active {
  transition: all 0.5s;
}
.fade-in-up-enter {
  transform: translateY(20px);
  opacity: 0;
}

.v-btn--floating {
  position: relative;
}

/* Ajuste para dispositivos móviles */
@media (max-width: 959px) {
  .login-container {
    height: calc(100vh);
    padding-bottom: 56px;
  }
}
</style>
